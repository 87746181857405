<template>
  <div>
    <el-page-header @back="goBack"
                    style="margin-bottom:20px"
                    content="商品订单"></el-page-header>
    <div id="Manage">
      <div class="header">
        <li class="long">订单编号: {{orderDetail.no}}</li>
        <li class="long">微信流水号: {{orderDetail.pur_order_no}}</li>
        <li>{{orderDetail.type}}订单</li>
        <li>供应商:{{supplierDetail.supplier_id}}</li>
        <li class="lastChild">下单时间：2021-09-02 11:25:13</li>
      </div>
      <div class="statusBar">
        <div class="left">
          <div class="first">待支付，等待买家支付</div>
          <div>买家如在<span>?</span>没有付款，交易自动关闭</div>
          <div>
            <span class="remake">备注:</span>?
          </div>
          <div>
            <span class="tip">观视界提醒:</span>?
          </div>
        </div>
        <div class="right">
          <el-steps :active='supplierDetail.status'
                    align-center>
            <el-step title="买家下单"
                     description="这是一段很长很长很长的描述性文字"></el-step>
            <el-step title="买家付款"
                     description="这是一段很长很长很长的描述性文字"></el-step>
            <el-step title="确认库存"
                     description="这是一段很长很长很长的描述性文字"></el-step>
            <el-step title="商家发货"
                     description="这是一段很长很长很长的描述性文字"></el-step>
            <el-step title="交易成功"
                     description="这是一段很长很长很长的描述性文字"></el-step>
          </el-steps>
        </div>
      </div>
      <div class="packageTab">
        <el-tabs type="card"
                 v-model="activeName">
          <el-tab-pane label="包裹"
                       name="first">
            <div class="packageInfo">
              <div class="left">
                <div>发货方式:{{supplierDetail.express_com_id}}</div>
                <div>发货人：</div>
                <div>发货时间：{{supplierDetail.shipping_time}}</div>
                <div>运单号：{{supplierDetail.express_num}}</div>
                <ul class="goods_detail">
                  <li v-for="goods in supplierDetail.shop_order_goods"
                      :key="goods.id">
                    <img :src='goods.goods_image'
                         alt=""
                         style="width:60px;height:60px">
                    <div>{{goods.goods_sku_name}}</div>
                    <div>数量:{{goods.quantity}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="orderInfo">
        <ul>
          <li>收货人信息</li>
          <div>收货人:<span> {{orderDetail.real_name}}</span></div>
          <div>联系电话:<span> {{orderDetail.mobile}}</span></div>
          <div>
            <div class="address1">收货地址:</div>
            <div class="address2"> {{orderDetail.address}}</div>
          </div>
        </ul>
        <ul>
          <li>配送信息</li>
          <div>配送方式:<span> {{supplierDetail.express_com_id}}</span></div>
          <div>发货时间:<span> {{supplierDetail.shipping_time}}</span></div>
        </ul>
        <ul>
          <li>付款信息</li>
          <div>实付金额:<span> {{supplierDetail.real_sum}}</span></div>
          <div>付款方式:<span> {{orderDetail.buy_type}}</span></div>
          <div>付款时间:<span> {{orderDetail.pay_time}}</span></div>
        </ul>
        <ul>
          <li>买家信息</li>
          <div>买家:<span>？</span></div>
          <div>买家留言:<span>？</span></div>
        </ul>
      </div>
      <div class="goodsTable">
        <ul class="headerTable">
          <li class="first">商品</li>
          <li>单价(元)</li>
          <li>数量</li>
          <li>优惠(元)</li>
          <li>小计(元)</li>
          <li>售后状态</li>
          <li>发货状态</li>
        </ul>
        <ul class="goodsDetail"
            v-for="item in supplierDetail.shop_order_goods"
            :key="item.id">
          <li class="first">
            <img :src="item.goods_image"
                 alt=""
                 style="width:70px;height:70px;margin-left:-20px">
            <div class="goods_name">{{item.goods_name}}</div>
            <div class="goods_sku_name">{{item.goods_sku_name}}</div>
          </li>
          <li>￥{{item.balance}}</li>
          <li>{{item.support_quantity}}</li>
          <li>￥?</li>
          <li>￥?</li>
          <li>{{item.support_status}}</li>
          <li>{{item.status}}</li>
        </ul>
      </div>
      <div class="bottom">
        <ul>
          <li>商品总价: ￥{{supplierDetail.total_price}}</li>
          <li>运费: ￥{{supplierDetail.freight_price}}</li>
          <li>优惠: ￥{{supplierDetail.chg_amount}}</li>
          <li>积分: ￥{{supplierDetail.integral}}</li>
          <li class="last">实收金额: <span>￥{{supplierDetail.real_sum}}</span></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      orderDetail: '',//订单详情
      supplierDetail: '',//供应商的订单信息
      activeName: 'first',
      order_id: ''//订单ID
    }
  },
  created () {
    //解决页面刷新无法获取数据问题
    var orderDetail = sessionStorage.getItem('orderDetail')
    this.orderDetail = JSON.parse(orderDetail)
    var supplierDetail = sessionStorage.getItem('supplierDetail')
    this.supplierDetail = JSON.parse(supplierDetail)
  },
  methods: {

    //返回
    goBack () {
      this.$router.push({ name: 'shopOrder' })
    }
  }
}
</script>
<style lang="scss" scoped>
#Manage {
  li {
    list-style: none;
  }
  width: 100%;
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  .header {
    li {
      float: left;
      font-size: 14px;
      margin-left: 30px;
    }
    .long {
      width: 25%;
      margin-left: 0px;
    }
    .lastChild {
      float: right;
    }
  }
  .statusBar {
    width: 100%;
    height: 180px;
    margin-top: 21px;
    border: 1px solid #dcdfe6;
    font-size: 12px;
    color: #606266;
    position: relative;
    .left {
      width: 23%;
      height: 93%;
      border-right: 1px solid #dcdfe6;
      padding-top: 13px;
      padding-left: 24px;
      display: inline-block;
      div {
        margin-top: 20px;
      }
      span {
        color: red;
      }
      .first {
        margin-top: 0px;
        font-size: 20px;
        font-weight: bold;
        color: #303133;
        line-height: 28px;
      }
      .remake {
        color: #409eff;
      }
      .tip {
        color: #e6a23c;
      }
    }
    .right {
      position: absolute;
      right: 0;
      width: 75%;
      height: 100%;
      // background-color: red;
      display: inline-block;
      // position: relative;
      /deep/.el-steps {
        margin-top: 50px;
      }
    }
  }
  .packageTab {
    margin-top: 40px;
    .packageInfo {
      // width: 99%;
      border: 1px solid #dcdfe6;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      .left {
        padding-top: 10px;
        padding-left: 24px;
        width: 23%;
        height: 100%;
        border-right: 1px solid #dcdfe6;
        div {
          margin-top: 13px;
        }
        .goods_detail {
          width: 90%;
          margin-left: -40px;
          display: flex;
          flex-wrap: wrap;
          li {
            // margin-left: 16px;
            width: 33%;
            div {
              margin-top: 3px;
              width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .orderInfo {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    padding-bottom: 15px;
    background-color: #f8fbff;
    ul {
      width: 22%;
      div {
        margin-top: 10px;
        font-size: 13px;
        color: #606266;
        line-height: 20px;
      }
      .address1 {
        margin-top: 0px;
        display: inline-block;
        float: left;
      }
      .address2 {
        margin-top: 0px;
        display: inline-block;
        // width: 200px;
      }
    }
  }
  .goodsTable {
    margin-top: 20px;
    ul {
      display: flex;
      // width: 100%;
      padding-top: 13px;
      border-bottom: 1px solid #dcdfe6;
      .first {
        width: 40%;
      }
      li {
        width: 10%;
      }
    }
    .headerTable {
      height: 35px;
      background: #f8fbff;
    }
    .goodsDetail {
      padding-bottom: 25px;
      li {
        display: flex;
        align-items: center;
      }
      .first {
        position: relative;
        .goods_name {
          width: 250px;
          position: absolute;
          left: 55px;
          top: 0px;
          color: #409eff;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .goods_sku_name {
          width: 400px;
          position: absolute;
          left: 55px;
          top: 35px;
          color: #909399;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .bottom {
    position: relative;
    ul {
      position: absolute;
      padding-bottom: 20px;
      top: -10px;
      right: 70px;
      li {
        font-size: 14px;
        color: #606266;
        margin-top: 10px;
      }
      .last {
        color: #303133;
        font-size: 17px;
        font-weight: bold;
        span {
          color: red;
        }
      }
    }
  }
}
</style>